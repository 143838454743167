export const userConstants = {
    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
    
    GETUSER_REQUEST: "USERS_GETUSER_REQUEST",
    GETUSER_SUCCESS: "USERS_GETUSER_SUCCESS",
    GETUSER_FAILURE: "USERS_GETUSER_FAILURE",

    LOGOUT: "USERS_LOGOUT",
    CLEAR_ERRORS: "CLEAR_ERRORS",

    CHANGE_INSTITUTION_REQUEST: "USERS_CHANGE_INSTITUTION_REQUEST",
    CHANGE_INSTITUTION_SUCCESS: "USERS_CHANGE_INSTITUTION_SUCCESS",
    CHANGE_INSTITUTION_FAILURE: "USERS_CHANGE_INSTITUTION_FAILURE",
}
