import axios from "axios"
import { utils } from "../_helpers/utils"
import { getTranslatedText as t } from "../_locale"

let base_url = process.env.REACT_APP_API_URL
// If the base_url has a slash at the end, remove it
if (base_url.endsWith("/")) {
    base_url = base_url.slice(0, -1)
}

export { base_url }

export const axiosInstance = axios.create({
    baseURL: `${base_url}`,
    headers: {
        Accept: "application/json"
    }
})

axiosInstance.interceptors.request.use(
    (config) => {
        const token = utils.getPasswordToken()
        if (token) {
            config.headers["Authorization"] = "Bearer " + token
        }
        if (utils.getStore("institutionId")) {
            config.headers["X-Institution"] = utils.getStore("institutionId")
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        let err = {
            code: error.response.status,
            message: error.response.data.message ?? error.response.data.error
        }

        if (
            err.message === "Authentication failed" ||
            err.message === "You are not authorized to access this application."
        ) {
            utils.createNotification("warning", err.message, 2000)

            if (!error.config.url.includes("login") && !window.location.href.includes("login")) {
                utils.deletePasswordToken()
                utils.deleteStore("persist:root")

                setTimeout(() => {
                    window.location = "/login"
                }, 1000)
            }
        } else {
            utils.createNotification("warning", err.message, t("warning"), 2000)
        }

        return Promise.reject(err)
    }
)
