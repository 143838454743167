import { utils } from "../_helpers/utils"

const config = {
    apiUrl: process.env.REACT_APP_API_URL
}

export const institutionService = {
    changeInstitution,
    getCurrentInstitution,
}

function changeInstitution(institutionId) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + utils.getPasswordToken()
        }
    }
    if (utils.getStore("institutionId")) {
        requestOptions.headers["X-Institution"] = utils.getStore("institutionId")
    }

    return fetch(`${config.apiUrl}/change-institution/${institutionId}`, requestOptions)
        .then(text => text.json())
        .then((response) => {
            if (response.data) {
                return response.data
            } else {
                console.error('changeInstitution error: ', response)
                // toast.error('Error changing institution. Please try again. If the problem persists, please contact support.')
                return Promise.reject(response)
            }
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

function getCurrentInstitution() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + utils.getPasswordToken()
        }
    }
    if (utils.getStore("institutionId")) {
        requestOptions.headers["X-Institution"] = utils.getStore("institutionId")
    }
    return fetch(`${config.apiUrl}/get-session-institution`, requestOptions)
        .then(text => text.json())
        .then((response) => {
            const id = response.data.id
            utils.setStore('institutionId', id)
            return id
        })
        .catch((error) => {
            return error
        })
}
