import React, { useEffect } from "react"
import { getTranslatedText as t } from "../../_locale"
import { resultActions } from "../../actions/result.actions"
import { useDispatch, useSelector } from "react-redux"
import { utils } from "../../_helpers/utils"
import { useHistory } from "react-router-dom"
import { sessionActions } from "../../actions/session.actions"
import ResultsButton from "../../components/result/ResultsButton"

const Results = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { results } = useSelector((state) => state.results)
    const spinner = useSelector((state) => state.spinner)
    const { user } = useSelector((state) => state.user)

    useEffect(() => {
        dispatch(resultActions.getAll())
        dispatch(sessionActions.clearSession())
    }, [user])

    if (!utils.arrayHasLength(results) && !spinner) {
        return (
            <div className="main-left-wrapper ">
                <div className="content-block">
                    <h3 className="tertiary-title">{t("results")}</h3>
                    <h6>{t("noExamResult")}</h6>
                </div>
            </div>
        )
    }

    return (
        <div className="main-left-wrapper results">
            <h3 className="tertiary-title">{t("results")}</h3>
            <div className="content-block">
                <div className="user-guide-wrap">
                    <div className="accordion" id="accordionExample">
                        {results.map((result, key) => {
                            return result.repeatable ? (
                                <div className="main" key={key}>
                                    <div id={`heading${key}`}>
                                        <div
                                            className="main single-guide border collapsed"
                                            key={key}
                                            data-toggle="collapse"
                                            data-target={`#collapse${key}`}
                                            aria-expanded="true"
                                            aria-controls={`collapse${key}`}
                                        >
                                            <div className="guide-content">
                                                <p className="exam-name">
                                                    <strong>{result.name}</strong>
                                                </p>
                                                {result.sessions && result.sessions.length == 1 && (
                                                    <p>{utils.getLocaleDateTime(result.sessions[0].ends_at)}</p>
                                                )}
                                            </div>

                                            {result.sessions && result.sessions.length > 1 ? (
                                                <div className="d-flex">
                                                    <span className="accordion-arrow"></span>
                                                </div>
                                            ) : (
                                                <ResultsButton
                                                    exam_code={result.exam_code}
                                                    session_id={
                                                        result.sessions && result.sessions.length > 0
                                                            ? result.sessions[0].id
                                                            : ""
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {result.sessions && result.sessions.length > 1 && (
                                        <div
                                            id={`collapse${key}`}
                                            className="collapse"
                                            aria-labelledby={`heading${key}`}
                                            data-parent="#accordionExample"
                                        >
                                            {result.sessions &&
                                                result.sessions.map((session, key_id) => {
                                                    return (
                                                        <div
                                                            className="single-guide accordion-item rounded p-3 border"
                                                            key={key_id}
                                                        >
                                                            <div className="gude-content">
                                                                <div
                                                                    onClick={() =>
                                                                        history.push(
                                                                            `/results/${result.exam_code}/${session.id}`
                                                                        )
                                                                    }
                                                                    className="text-left d-flex flex-column w-100"
                                                                >
                                                                    <p className="nth-session">
                                                                        <strong>
                                                                            {result.sessions.length - key_id}. {t("attempt")}
                                                                        </strong>
                                                                    </p>
                                                                    <p>{utils.getLocaleDateTime(session.starts_at)}</p>
                                                                </div>
                                                            </div>
                                                            <ResultsButton
                                                                exam_code={result.exam_code}
                                                                session_id={
                                                                    result.sessions && result.sessions.length > 0
                                                                        ? result.sessions[0].id
                                                                        : ""
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="main single-guide border non-accordion" key={key}>
                                    <div className="gude-content">
                                        <p className="exam-name">
                                            <strong className="bold">{result.name}</strong>
                                        </p>
                                        {result.sessions && result.sessions.length > 0 && (
                                            <p>{utils.getLocaleDateTime(result.sessions[0].ends_at)}</p>
                                        )}
                                    </div>

                                    <ResultsButton
                                        exam_code={result.exam_code}
                                        session_id={
                                            result.sessions && result.sessions.length > 0 ? result.sessions[0].id : ""
                                        }
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Results
