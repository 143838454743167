import { utils } from "../_helpers/utils"

export const userService = {
    logout
}

const config = {
    ssoUrl: process.env.REACT_APP_SSO_API_URL
}

async function logout() {
    utils.deletePasswordToken()
    utils.deleteStore("persist:root")

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + utils.getPasswordToken()
        }
    }

    try {
        await fetch(`${config.ssoUrl}/api/logout`, requestOptions)
        .then(() => {
            utils.deletePasswordToken()
            return true
        })
    } catch (error) {
        return await Promise.reject(error)
    }
}
