import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getTranslatedText as t } from "../../_locale"
import { languageActions } from "../../actions/language.actions"
import { userActions } from "../../actions/user.actions"
import LogOut from "../../styles/icons/Logout"
import MobileLogo from "../../styles/img/logo-mobile.png"
import Logo from "../../styles/img/logo.png"
import { utils } from "../../_helpers/utils"
import LayersIcon from "../partials/icons/Layers"
import GridIcon from "../partials/icons/Grid"

const Header = ({ hidePrint = false }) => {
    let history = useHistory()
    const dispatch = useDispatch()
    const { user, loading, loggedIn } = useSelector((state) => state.user)
    const isStudent = user && utils.isUserStudent(user)
    const { language } = useSelector((state) => state.language)
    const appsDropdownItems = [
        { value: "console", label: "Console", active: true, hideStudent: true },
        { value: "book", label: "Book", active: true },
        { value: "class", label: "Class", active: true },
        { value: "exam", label: "Exam", active: false },
        { value: "content", label: "Content", active: false },
        { value: "lms", label: "LMS", active: false }
    ]

    const handleAppSelectClick = (item) => {
        if (!item.active) return

        let url = item.value === "" ? "https://apitwist.com/" : `https://${item.value}.apitwist.com/home`
        const institutionId = utils.getStore("institutionId")
        if (institutionId) {
            url += `?institutionId=${institutionId}`
        }
        window.open(url, "_blank")
    }

    const logOutHandler = (e) => {
        e.preventDefault()
        dispatch(userActions.logout())
    }

    const changeLocale = (locale) => {
        dispatch(languageActions.changeLanguage(locale))
    }

    const hasMultipleRoles = () => {
        if (!user.roles) return false
        if (user.roles.length === 1) return false
        return user.roles.filter((role) => role.institution.apps.some((app) => app.name === "Exam")).length > 1
    }

    // useEffect(() => {
    //     if (!user && loading === false && loggedIn === false) {
    //         utils.deletePasswordToken()
    //         setTimeout(() => {
    //             history.push("/login")
    //         }, 300)
    //     }
    // }, [user, loggedIn, loading])

    return (
        <header className={`header-area ${hidePrint ? "d-print-none" : ""}`}>
            <div className="container-fluid">
                <div className="header-wrap">
                    <a href="/" className="logo d-none d-md-block">
                        <img src={Logo} alt="ApiTwist" />
                    </a>
                    <a href="/" className="logo d-md-none">
                        <img src={MobileLogo} alt="ApiTwist" />
                    </a>

                    <div className="d-flex align-items-center right">
                        <div className="lang">
                            <span className="text-uppercase">{language}</span>

                            <ul className="lang_menu">
                                <li
                                    className={language == "en" ? "active" : ""}
                                    onClick={() => {
                                        changeLocale("en")
                                    }}
                                >
                                    <span className="lang_shortcode">EN</span> {t("english")}
                                </li>
                                <li
                                    className={language === "tr" ? "active" : ""}
                                    onClick={() => {
                                        changeLocale("tr")
                                    }}
                                >
                                    <span className="lang_shortcode">TR</span> {t("turkish")}
                                </li>
                            </ul>
                        </div>

                        {/* APP SELECT */}
                        <div className="lang">
                            <span style={{ fontSize: "1.8rem" }}>
                                <GridIcon />
                            </span>

                            <ul className="lang_menu">
                                {appsDropdownItems.map((item) => {
                                    return isStudent && item.hideStudent ? null : (
                                        <li
                                            key={item.value}
                                            className={`${!item.active ? "disabled" : ""}`}
                                            onClick={() => handleAppSelectClick(item)}
                                        >
                                            <span className="lang_shortcode">{item.label}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <ul className="user-part">
                            {/* <li>
                            <a href="#">
                            <img src={Bell} alt="" />
                            </a>
                            <ul className="submenu notification">
                            <li>
                            <a href="#" className="white">
                            Size 2 sınav atandı. <span>1 gün önce</span>
                            </a>
                            </li>
                            <li>
                            <a href="#" className="white">
                            Rehberinizden mesaj var. <span>3 gün önce</span>
                            </a>
                            </li>
                            <li>
                            <a href="#">
                            Rehberinizden mesaj var. <span>30.10.2019</span>
                            </a>
                            </li>
                            </ul>
                            </li> */}

                            {loggedIn && user && user.name && (
                                <li>
                                    <p>
                                        {user.name} {user.surname}
                                    </p>
                                    <p>{utils.getTitleForHeader(user)}</p>
                                </li>
                            )}
                            <li className="user-img shadow ml-3">
                                <a href="#">
                                    <span>
                                        {loggedIn &&
                                            user &&
                                            user.name &&
                                            user.name.charAt(0) +
                                                (user.surname && user.surname !== null ? user.surname.charAt(0) : "")}
                                    </span>
                                </a>
                                <ul className="submenu text-left">
                                    {loggedIn && user && user.roles && hasMultipleRoles() && (
                                        <>
                                            <li className="submenu-item">
                                                <LayersIcon /> {t("institutions")}
                                            </li>
                                            {user.roles
                                                .filter((role) =>
                                                    role.institution.apps.some((app) => app.name === "Exam")
                                                )
                                                .map((role) => (
                                                    <li
                                                        className={`submenu-item ${
                                                            role.institution_id === user.activeInstitution?.id &&
                                                            "active"
                                                        }`}
                                                        key={role.institution_id}
                                                        onClick={() =>
                                                            dispatch(userActions.changeInstitution(role.institution_id))
                                                        }
                                                    >
                                                        {role.institution.name}
                                                        {role.institution_id === user.activeInstitution?.id && (
                                                            <div className="ml-2 d-inline">✔</div>
                                                        )}
                                                    </li>
                                                ))}

                                            <hr />
                                        </>
                                    )}
                                    <li className="submenu-item" onClick={logOutHandler}>
                                        <LogOut className="mr-2" /> {t("logout")}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
