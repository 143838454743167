import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { Provider } from "react-redux"
import configureStore from "./redux/configureStore"
import { PersistGate } from "redux-persist/integration/react"
import "react-notifications/lib/notifications.css"

import "./styles/css/bootstrap.min.css"
import "./styles/css/all.min.css"
import "./styles/css/font.css"
import "./styles/css/style.css"
import "./styles/css/slick.css"
import "./styles/css/Util.css"
import "./styles/css/main.css"
import "./styles/css/responsive.css"

const { store, persistor } = configureStore()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
