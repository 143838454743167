import { NotificationManager } from "react-notifications"
import { getTranslatedText as t } from "../_locale"

export const utils = {
    addAnswersSum,
    arrayHasLength,
    capitalize,
    checkPageInResult,
    checkQuestionIsGroup,
    clearStore,
    convertObjectToArray,
    createNotification,
    deletePasswordToken,
    deleteStore,
    filterQuestions,
    findFormErrors,
    getAllQuestions,
    getCalendarType,
    getDate,
    getFirstPath,
    getLocaleDateTime,
    getNumberedParanthesis,
    getRole,
    getPageLanguage,
    getPasswordToken,
    getStore,
    getTitleForHeader,
    handleAnswersPerQuestionId,
    isValidEmail,
    isUserStudent,
    objectHasLength,
    replaceWithBold,
    resizeImages,
    sanitizeHtml,
    setStore,
    shuffleArray,
    sortByField,
    truncate,
    validateClassForm
}

function setStore(key, value) {
    if (!key) return
    if (typeof value !== "string") {
        value = JSON.stringify(value)
    }
    return window.localStorage.setItem(key, value)
}

function getStore(key) {
    if (!key) return
    return window.localStorage.getItem(key)
}

function deleteStore(key) {
    if (!key) return
    return window.localStorage.removeItem(key)
}

function clearStore() {
    return window.localStorage.clear()
}

function isValidEmail(value) {
    return !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(value))
}

function findFormErrors(formValues) {
    const newErrors = {}
    let required_val_err = t("VAL_REQUIRED")
    let min_val_err = t("VAL_MIN_CHAR")
    let valid_email = t("VAL_MAIL")
    let max_val_err = t("VAL_MAX_CHAR")

    formValues.forEach((formValue) => {
        if (
            (formValue.input === undefined || formValue.input === null || formValue.input === "") &&
            formValue.validations.required
        ) {
            newErrors[formValue.name.toLowerCase()] = required_val_err.replace(
                ":field_name",
                formValue.label ?? t(formValue.name)
            )
        }

        if (formValue.validations.min && formValue.validations.min !== null) {
            if (
                formValue.input !== null &&
                formValue.input !== undefined &&
                formValue.input !== "" &&
                formValue.input.length < formValue.validations.min + 1
            ) {
                newErrors[formValue.name.toLowerCase()] = min_val_err
                    .replace(":field_name", t(formValue.name.toLowerCase()))
                    .replace(":min", formValue.validations.min + 1)
                    .replace(":val_max", "")
            }
        }

        if (
            formValue.validations.min &&
            formValue.validations.min !== null &&
            formValue.validations.max &&
            formValue.validations.max !== null
        ) {
            if (
                formValue.input !== null &&
                formValue.input !== undefined &&
                formValue.input.length > formValue.validations.max + 1
            ) {
                newErrors[formValue.name.toLowerCase()] = min_val_err
                    .replace(":field_name", t(formValue.name.toLowerCase()))
                    .replace(":min", formValue.validations.min + 1)
                    .replace(":val_max", max_val_err.replace(":max", formValue.validations.max + 1))
            }
        }
        if (
            formValue.validations.required &&
            formValue.name === "Email" &&
            (formValue.input !== undefined || formValue.input !== null || formValue.input !== "") &&
            !utils.isValidEmail(formValue.input)
        ) {
            newErrors[formValue.name.toLowerCase()] = valid_email
        }
    })

    return newErrors
}

function validateClassForm({ key = undefined, value = undefined, formErrors, setFormErrors, formValues }) {
    let newErrors

    if (key) {
        newErrors = formValues[key] && utils.findFormErrors([{ ...formValues[key], input: value }])
        if (!newErrors) {
            return
        }
        if (Object.keys(newErrors).length > 0) {
            setFormErrors((prevState) => ({ ...prevState, ...newErrors }))
        } else {
            const newFormErrors = { ...formErrors }
            delete newFormErrors[formValues[key].name.toLowerCase()]
            setFormErrors(newFormErrors)
        }
    } else {
        let formValuesArray
        formValuesArray = Object.values(formValues).map((field) => field)
        newErrors = utils.findFormErrors(formValuesArray)
        setFormErrors(newErrors)
    }
    if (Object.keys(newErrors).length > 0) {
        return false
    } else {
        return true
    }
}

function arrayHasLength(arr) {
    return arr && arr.length > 0
}

function objectHasLength(obj) {
    return obj && Object.keys(obj).length > 0
}

function createNotification(type, message, title, timeout = 5000) {
    switch (type) {
        case "info":
            NotificationManager.info(message, title, timeout)
            break
        case "success":
            NotificationManager.success(message, title, timeout)
            break
        case "warning":
            NotificationManager.warning(message, title, timeout)
            break
        case "error":
            NotificationManager.error(message, title, timeout)
            break
        default:
            return
    }
}

function filterQuestions(filterType, questions, questionsControl) {
    if (utils.arrayHasLength(questions)) {
        return questions.filter((question) => {
            switch (filterType) {
                case "CONTROL":
                    return (
                        utils.objectHasLength(questionsControl) &&
                        questionsControl[question.session_id] !== undefined &&
                        questionsControl[question.session_id].length > 0 &&
                        questionsControl[question.session_id].includes(question.id)
                    )
                case "EMPTY":
                    return question?.storedAnswer
                        ? !utils.arrayHasLength(question.storedAnswer)
                        : !utils.arrayHasLength(question.student_answer)
                case "FILLED":
                    return question?.storedAnswer
                        ? utils.arrayHasLength(question.storedAnswer)
                        : utils.arrayHasLength(question.student_answer)
                case "WRONG":
                    return question?.questionStatus === false
                case "CORRECT":
                    return question?.questionStatus === true
                default:
                    return question
            }
        })
    }
}

function handleAnswersPerQuestionId(originalData) {
    const data = {
        answers: {}
    }

    for (let course in originalData) {
        for (let key in originalData[course]) {
            if (key) {
                const questionType = originalData[course][key].question_type_id
                const answerData = originalData[course][key].answer

                switch (questionType) {
                    case 1:
                        data.answers[key] = answerData.length > 0 ? answerData[0].answer_id : null

                        break
                    case 2:
                        data.answers[key] = answerData.length > 0 ? answerData.map((answer) => answer.answer_id) : [""]
                        break
                    case 3:
                        data.answers[key] =
                            answerData.length > 0 ? (answerData[0].answer !== null ? answerData[0].answer : null) : null
                        break
                    default:
                        break
                }
            }
        }
    }
    return data
}

function getAllQuestions(courses) {
    return courses.reduce((acc, course) => acc.concat(course.questions), [])
}

function getFirstPath() {
    return window.location.pathname.split("/")[1]
}

function getLocaleDateTime(dateString) {
    if (!dateString) return
    const date = new Date(dateString)

    const options = {
        timeZone: "Europe/Istanbul",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: false,
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    }
    let dateFormat
    switch (getPageLanguage()) {
        case "en":
            dateFormat = "en-US"
            break
        case "tr":
            dateFormat = "tr-TR"
            break
        default:
            break
    }

    const localDate = date.toLocaleDateString(dateFormat, options).slice(0, -3)

    return `${localDate}`
}

function checkPageInResult() {
    return window.location.pathname.includes("results") && window.location.pathname.includes("answers")
}

function capitalize(str) {
    if (!str) return str

    return str[0].toUpperCase() + str.slice(1)
}

function getCalendarType() {
    switch (getStore("locale")) {
        case "en":
            return "US"
        default:
            return "ISO 8601"
    }
}

function getDate(date) {
    var year = date.getFullYear()
    var month = ("0" + (date.getMonth() + 1)).slice(-2)
    var day = ("0" + date.getDate()).slice(-2)

    return year + "-" + month + "-" + day
}

function replaceWithBold(sentence, strong) {
    return (
        <span>
            {sentence.substring(0, sentence.indexOf(strong))}
            <strong>{strong}</strong>
            {sentence.substring(sentence.indexOf(strong) + strong.length)}
        </span>
    )
}

function truncate(str, maxlength) {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + "…" : str
}

function getPageLanguage() {
    return document.querySelector("html").getAttribute("lang")
}

function shuffleArray(array) {
    return array.sort(() => 0.5 - Math.random())
}

function sortByField(array, field) {
    return array.sort((a, b) => a[field] - b[field])
}

function checkQuestionIsGroup(question) {
    return question.hasOwnProperty("children")
}

function convertObjectToArray(array) {
    return Object.values(array)
}

function addAnswersSum(data) {
    let true_answers = 0
    let false_answers = 0
    let empty_answers = 0
    Object.values(data.course_results).forEach((result) => {
        true_answers += result.true_answers
        false_answers += result.false_answers
        empty_answers += result.empty_answers
    })

    return { ...data, true_answers, false_answers, empty_answers }
}

function getNumberedParanthesis(paragraph, groupQuestionsArray) {
    let regex = /\(\s*\)/g
    let matches = paragraph.match(regex)
    if (matches) {
        for (let i = 0; i < matches.length; i++) {
            paragraph = paragraph.replace(matches[i], `(${groupQuestionsArray[i]})`)
        }
    }

    return paragraph
}

function sanitizeHtml(html) {
    // make all checks in this function to summarize all the sanitization process
    let sanitized = html
    sanitized = resizeImages(html)
    sanitized = checkTargetInHtml(sanitized, "_blank")

    return sanitized
}

function resizeImages(html) {
    // check if html has <img tag. If has then add max-height: 60% to the style attribute
    // to prevent the image from overflowing the container
    let imgRegex = /<img.*?>/g
    let imgTags = html.match(imgRegex)
    if (imgTags) {
        imgTags.forEach((imgTag) => {
            // if img tag has style attribute then add max-height: 60% to the style attribute
            // if not then add style attribute with max-height: 60%
            let styleRegex = /style=".*?"/g
            let styleAttr = imgTag.match(styleRegex)
            if (styleAttr) {
                let newStyleAttr = styleAttr[0].replace('style="', 'style="max-width: 60%; height: auto; ')
                html = html.replace(styleAttr[0], newStyleAttr)
            } else {
                let newImgTag = imgTag.replace(">", ' style="max-width: 60%; height: auto;">')
                html = html.replace(imgTag, newImgTag)
            }
        })
    }
    return html
}

function checkTargetInHtml(html, target) {
    // If html has a anchor tag with href attribute
    // then check if the href attribute has the target _blank
    // if not then add target _blank to the anchor tag
    let aTagRegex = /<a.*?>/g
    let aTags = html.match(aTagRegex)
    if (aTags) {
        aTags.forEach((aTag) => {
            let targetRegex = /target=".*?"/g
            let targetAttr = aTag.match(targetRegex)
            if (targetAttr) {
                if (!targetAttr[0].includes("_blank")) {
                    let newTargetAttr = targetAttr[0].replace('target="', 'target="_blank" ')
                    html = html.replace(targetAttr[0], newTargetAttr)
                }
            } else {
                let newATag = aTag.replace(">", ' target="_blank">')
                html = html.replace(aTag, newATag)
            }
        })
    }
    return html
}

function getPasswordToken() {
    return (
        getStore("passwordToken") &&
        getStore("passwordToken") !== "undefined" &&
        getStore("passwordToken")
    )
}

function deletePasswordToken() {
    deleteStore("passwordToken")
}

function getRole(user) {
    let role = null

    if (user && objectHasLength(user)) {
        if(user.activeInstitution && objectHasLength(user.activeInstitution)){
            role = user.activeInstitution.role
        } else {
            role = user.roles[0].name
        }
    }

    return role ?? "student"
}

function isUserStudent(user) {
    return getRole(user) === "student"
}

function getTitleForHeader(user) {
    const role = getRole(user).split('-').map(capitalize).join(' ');
    let title = role;

    if (user.activeInstitution && objectHasLength(user.activeInstitution) && user.activeInstitution.name) {
        let institutionName = user.activeInstitution.name;

        if (institutionName.length > 25) {
            institutionName = institutionName.split(' ').map(word => word.slice(0, 3)).join('. ') + '.';
        }

        title = `${institutionName} - ${role}`;
    }

    return title;
}
