import React from "react"
import { useSelector } from "react-redux"
import { getTranslatedText as t } from "../../_locale"
import { utils } from "../../_helpers/utils"

const ExamAccordionTable = ({ results, name, setStudentDetail }) => {
    const { language } = useSelector((state) => state.language)
    return (
        <>
            {Object.values(results)
                .sort((a, b) => {
                    return a.course_name[language].localeCompare(b.course_name[language])
                })
                .map((course, courseIndex) => (
                    <React.Fragment key={courseIndex}>
                        <thead id="accordion course-result-table">
                            <tr>
                                <td className="order-column">
                                    <h5>{name}</h5>
                                </td>
                                <td></td>
                                <td colSpan={4} className="font-weight-bold text-center merged-column">
                                    <b> {course.course_name[language]}</b>
                                </td>
                            </tr>
                            <tr>
                                <td className="order-column"></td>
                                <td></td>
                                <td className="text-success font-weight-bold text-center">{t("t")}</td>
                                <td className="text-danger font-weight-bold text-center">{t("f")}</td>
                                <td className="text-primary font-weight-bold text-center">{t("e")}</td>
                                <td className="font-weight-bold text-center">{t("score")}</td>
                            </tr>
                        </thead>

                        <tbody className="table-row" id={course.course_name[language] + courseIndex}>
                            {Object.values(course.students)
                                .sort((a, b) => {
                                    return b.result.score - a.result.score
                                })
                                .map((studentResult, studentResultIndex) => (
                                    <React.Fragment key={studentResultIndex}>
                                        <tr
                                            id={course.course_name[language] + courseIndex}
                                            className="table-row cursor-pointer"
                                            onClick={() => {setStudentDetail(studentResult)}}
                                        >
                                            <td className="text-center order-column">{studentResultIndex + 1}</td>
                                            <td className="text-left pl-3">
                                                {studentResult.student.name} {studentResult.student.surname}
                                            </td>
                                            <td className="text-center">
                                                <b className="text-success">{studentResult.result.true_answers}</b>
                                            </td>
                                            <td className="text-center">
                                                <b className="text-danger">{studentResult.result.false_answers}</b>
                                            </td>
                                            <td className="text-center">
                                                <b className="text-primary">{studentResult.result.empty_answers}</b>
                                            </td>
                                            <td className="text-center">
                                                <b>{studentResult.result.score}</b>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}

                            <tr className="table-row cursor-pointer bg-dark text-light">
                                <td className="order-column"></td>
                                <td className="text-left pl-3">{t("average")}</td>
                                <td className="text-center">
                                    <b className="text-success">{course.true_answers}</b>
                                </td>
                                <td className="text-center">
                                    <b className="text-danger" style={{ filter: "brightness(2)" }}>
                                        {course.false_answers}
                                    </b>
                                </td>
                                <td className="text-center">
                                    <b className="text-primary">{course.empty_answers}</b>
                                </td>
                                <td className="text-center">
                                    <b>{course.score}</b>
                                </td>
                            </tr>
                        </tbody>
                    </React.Fragment>
                ))}
        </>
    )
}

export default ExamAccordionTable
