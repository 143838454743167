import React, { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto"
import { getTranslatedText as t } from "../../_locale"
import { utils } from "../../_helpers/utils"
import DoughnutChart from "../partials/charts/DoughnutChart"
import BarChart from "../partials/charts/BarChart"
import EvaluationCard from "../partials/EvaluationCard"
import Users from "../../styles/icons/Users"
import ExamAccordionTable from "./ExamAccordionTable"
import ExamResultCard from "./ExamResultCard"
import SectionHeader from "./SectionHeader"
import StudentDetailCard from "./StudentDetailCard"
import { examActions } from "../../actions/exam.actions"

ChartJS.register(ArcElement, Tooltip, Legend)

const ExamReport = () => {
    const dispatch = useDispatch()
    const { exam_code } = useParams()
    const { language } = useSelector((state) => state.language)
    const { result } = useSelector((state) => state.exams)
    const [studentDetail, setStudentDetail] = useState(null)
    const [cartData, setChartData] = useState({
        students_count: 0,
        true_answers: 0,
        false_answers: 0,
        empty_answers: 0,
        score: 0
    })

    let is_answer_show_date_in_progress = useMemo(
        () =>
            new Date() > new Date(result.answer_show_start_time) && new Date() < new Date(result.answer_show_end_time),
        [result]
    )

    useEffect(() => {
        dispatch(examActions.getResult(exam_code))
    }, [])

    useEffect(() => {
        if (!result || !result.results || !result.results.length) {
            return
        }

        const students_count = result.results.reduce(
            (sum, res) => sum + res.courses.reduce((courseSum, course) => courseSum + course.students.length, 0),
            0
        )

        let data = {
            students_count: students_count,
            true_answers: parseFloat(
                (
                    result.results.reduce(
                        (sum, res) =>
                            sum +
                            res.courses.reduce(
                                (courseSum, course) =>
                                    courseSum +
                                    course.students.reduce(
                                        (studentSum, student) => studentSum + student.result.true_answers,
                                        0
                                    ),
                                0
                            ),
                        0
                    ) / students_count
                ).toFixed(2)
            ),
            false_answers: parseFloat(
                (
                    result.results.reduce(
                        (sum, res) =>
                            sum +
                            res.courses.reduce(
                                (courseSum, course) =>
                                    courseSum +
                                    course.students.reduce(
                                        (studentSum, student) => studentSum + student.result.false_answers,
                                        0
                                    ),
                                0
                            ),
                        0
                    ) / students_count
                ).toFixed(2)
            ),
            empty_answers: parseFloat(
                (
                    result.results.reduce(
                        (sum, res) =>
                            sum +
                            res.courses.reduce(
                                (courseSum, course) =>
                                    courseSum +
                                    course.students.reduce(
                                        (studentSum, student) => studentSum + student.result.empty_answers,
                                        0
                                    ),
                                0
                            ),
                        0
                    ) / students_count
                ).toFixed(2)
            ),
            score: parseFloat(
                (
                    result.results.reduce(
                        (sum, res) =>
                            sum +
                            res.courses.reduce(
                                (courseSum, course) =>
                                    courseSum +
                                    course.students.reduce(
                                        (studentSum, student) =>
                                            studentSum +
                                            (student.result.true_answers * 100) /
                                                (student.result.true_answers +
                                                    student.result.false_answers +
                                                    student.result.empty_answers),
                                        0
                                    ),
                                0
                            ),
                        0
                    ) / students_count
                ).toFixed(2)
            )
        }
        setChartData(data)
    }, [result])

    const getScoreTable = () => {
        if (!result.results) return null
        return result.results.map((classroom, classroomIndex) => (
            <div key={classroomIndex} className="mt-5">
                <div className="bg-white p-3 p-sm-5 rounded">
                    <table className="table table-striped table-hover">
                        <ExamAccordionTable
                            results={classroom["courses"]}
                            setStudentDetail={setStudentDetail}
                            name={
                                classroom.classroom_name
                                    ? classroom.classroom_name[language] ?? t("students")
                                    : t("students")
                            }
                        />
                    </table>

                    <div className="d-flex justify-content-end">
                        <a href={`/results/${exam_code}/pdf_report/${classroom.classroom_id}`} className="btn btn-lg btn-primary" target="_blank" rel="noopener noreferrer">
                            <i className="fal fa-file-pdf fa-lg mr-2"></i>PDF Report
                        </a>
                    </div>

                </div>
            </div>
        ))
    }

    const getCharts = () => {
        if (!result.results) return null
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="mb-lg-0 bg-white rounded p-5 h-100">
                            <h5 className="mb-5">{t("examResult")}</h5>
                            <div className="row flex-column flex-md-row">
                                <div className={`col-12 ${result.results.length > 1 ? "col-lg-3" : "col-lg-4"} mb-5`}>
                                    <div className="row">
                                        <div className="col-4 col-lg-12">
                                            <EvaluationCard
                                                text={t("questions")}
                                                value={result.question_count}
                                                className="mb-5"
                                                children={
                                                    <i
                                                        className="fa fa-question"
                                                        style={{
                                                            width: "24px",
                                                            height: "24px",
                                                            fontSize: "18px",
                                                            lineHeight: "1.5",
                                                            color: "#ff9f43"
                                                        }}
                                                    />
                                                }
                                                childClassName="bg-light-orange"
                                            />
                                        </div>
                                        <div className="col-4 col-lg-12">
                                            <EvaluationCard
                                                text={t("examTime")}
                                                value={result.exam_time}
                                                className="mb-5"
                                                children={
                                                    <i
                                                        className="fa fa-clock"
                                                        style={{
                                                            width: "24px",
                                                            height: "24px",
                                                            fontSize: "18px",
                                                            lineHeight: "1.5",
                                                            color: "#00cfe8"
                                                        }}
                                                    />
                                                }
                                                childClassName="bg-light-blue"
                                            />
                                        </div>
                                        <div className="col-4 col-lg-12">
                                            <EvaluationCard
                                                text={t("participation")}
                                                children={<Users fill="#7367f0" />}
                                                value={cartData.students_count}
                                                childClassName="bg-light-purple"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <ExamResultCard sessionData={cartData} classrooms={result.results} />
                            </div>
                        </div>
                    </div>
                </div>

                {result && result.results && result.results.length > 1 && (
                    <div className="row">
                        {Object.values(result.results)
                            .sort((a, b)    => {
                                return a.classroom_name[language].localeCompare(b.classroom_name[language])
                            })
                            .map((classroom, index) => {
                                return (
                                    <div className="col-12 mt-5" key={index}>
                                        <div className="mb-lg-0 bg-white rounded p-5 h-100">
                                            <h5 className="mb-4">{classroom.classroom_name[language]}</h5>
                                            <div className="row flex-column flex-md-row classroom-results">
                                                <div
                                                    className="col-6 col-lg-4 d-flex justify-content-center"
                                                    style={{ height: "250px" }}
                                                >
                                                    <BarChart
                                                        dataSet={[
                                                            classroom.true_answers,
                                                            classroom.false_answers,
                                                            classroom.empty_answers
                                                        ]}
                                                    />
                                                </div>

                                                <div
                                                    className="col-6 col-lg-4 d-flex align-items-center justify-content-center"
                                                    style={{ height: "250px" }}
                                                >
                                                    <DoughnutChart data={classroom} />
                                                </div>

                                                {classroom.subjects && utils.objectHasLength(classroom.subjects) &&
                                                 <div className="col-12 col-lg-4 d-flex flex-column justify-content-center">
                                                    {Object.values(classroom.subjects).map((subject, index) => {
                                                        const total = subject.true_answers + subject.false_answers + subject.empty_answers
                                                        const truePercentage = (parseFloat(subject.true_answers) / parseFloat(total)) * 100
                                                        const falsePercentage = (parseFloat(subject.false_answers) / parseFloat(total)) * 100
                                                        const emptyPercentage = (parseFloat(subject.empty_answers) / parseFloat(total)) * 100
                                                        return (
                                                            <div key={index} className="mb-3">
                                                                <h6>{subject.name[language]}</h6>
                                                                <div className="progress" style={{ height: "6px" }}>
                                                                    <div
                                                                        className="progress-bar"
                                                                        role="progressbar"
                                                                        style={{
                                                                            width: `${truePercentage}%`,
                                                                            backgroundColor: "rgb(75, 192, 192)" // Green color for true
                                                                        }}
                                                                        aria-valuenow={subject.true_answers}
                                                                        aria-valuemin="0"
                                                                        aria-valuemax={total}
                                                                    ></div>
                                                                    <div
                                                                        className="progress-bar"
                                                                        role="progressbar"
                                                                        style={{
                                                                            width: `${falsePercentage}%`,
                                                                            backgroundColor: "rgb(255, 99, 132)" // Red color for false
                                                                        }}
                                                                        aria-valuenow={subject.false_answers}
                                                                        aria-valuemin="0"
                                                                        aria-valuemax={total}
                                                                    ></div>
                                                                    <div
                                                                        className="progress-bar"
                                                                        role="progressbar"
                                                                        style={{
                                                                            width: `${emptyPercentage}%`,
                                                                            backgroundColor: "rgb(255, 206, 86)" // Yellow color for empty
                                                                        }}
                                                                        aria-valuenow={subject.empty_answers}
                                                                        aria-valuemin="0"
                                                                        aria-valuemax={total}
                                                                    ></div>
                                                                </div>  
                                                                
                                                                <div className="d-flex flex-wrap">
                                                                    {subject.true_answers > 0 && (
                                                                        <div className="d-flex align-items-center mr-3 mb-2">
                                                                            <span className="progress-default progress-success"></span>
                                                                            <span className="text-small text-muted">
                                                                                {t("true")}: {subject.true_answers} ({truePercentage.toFixed(1)}%)
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    {subject.false_answers > 0 && (
                                                                        <div className="d-flex align-items-center mr-3 mb-2">
                                                                            <span className="progress-default progress-danger"></span>
                                                                            <span className="text-small text-muted">
                                                                                {t("false")}: {subject.false_answers} ({falsePercentage.toFixed(1)}%)
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    {subject.empty_answers > 0 && (
                                                                        <div className="d-flex align-items-center mr-3 mb-2">
                                                                            <span className="progress-default progress-warning"></span>
                                                                            <span className="text-small text-muted">
                                                                                {t("empty")}: {subject.empty_answers} ({emptyPercentage.toFixed(1)}%)
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                        })
                                                    }
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                )}
            </>
        )
    }

    const getDetail = () => {
        return <StudentDetailCard studentResult={studentDetail} setStudentDetail={setStudentDetail} />
    }

    const getComperasionTable = () => {
        if (!result.results) return null
        if (result.results.length === 1) return null
        return (
            <div className="bg-white p-3 p-sm-5 mt-5 rounded">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <td className="font-weight-bold">{t("grade")}</td>
                            <td className="font-weight-bold">{t("count")}</td>
                            {Object.values(result.results[0].subjects).map((subject, index) => {
                                return <td className="text-center font-weight-bold" key={index}>{subject.name[language]}</td>
                            })}
                            <td className="text-center font-weight-bold">{t("average")}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(result.results).map((classroom, index) => {
                            return <tr key={index}>
                                <td>{classroom.classroom_name[language]}</td>
                                <td>{classroom.courses[0].students.length}</td>
                                {Object.values(classroom.subjects).map((subject, index) => {
                                    const total = subject.true_answers + subject.false_answers + subject.empty_answers
                                    const percentage = (parseFloat(subject.true_answers) / parseFloat(total)) * 100
                                    return (
                                        <td key={index}>
                                            <div className="d-flex align-items-center justify-content-start">
                                                <div 
                                                    style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        borderRadius: '50%',
                                                        background: `conic-gradient(#4CAF50 ${percentage * 3.6}deg, #d2d2d2 0deg)`,
                                                        marginRight: '8px',
                                                        position: 'relative',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <div style={{
                                                        width: '15px',
                                                        height: '15px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'white'
                                                    }} />
                                                </div>
                                                {percentage.toFixed(1)}%
                                            </div>
                                        </td>
                                    )
                                })}
                                <td>
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div 
                                            style={{
                                                width: '25px',
                                                height: '25px',
                                                borderRadius: '50%',
                                                background: `conic-gradient(#4CAF50 ${classroom.score * 3.6}deg, #d2d2d2 0deg)`,
                                                marginRight: '8px',
                                                position: 'relative',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <div style={{
                                                width: '15px',
                                                height: '15px',
                                                borderRadius: '50%',
                                                backgroundColor: 'white'
                                            }} />
                                        </div>
                                    {classroom.score}
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </div>
        )
    }

    const getResults = () => {
        switch (result.score_status) {
            case "show":
                return (
                    <div className="row">
                        <div className={studentDetail ? "col-8" : "col-12"}>
                            {getCharts()}
                            {result.results && result.results.length > 1 && getComperasionTable()}
                            {getScoreTable()}
                        </div>
                        {studentDetail && <div className="col-4">{getDetail()}</div>}
                    </div>
                )
            case "timed":
                if (result && is_answer_show_date_in_progress) {
                    return (
                        <div className="row">
                            <div className={studentDetail ? "col-8" : "col-12"}>
                                {getCharts()}
                                {result.results && result.results.length > 1 && getComperasionTable()}
                                {getScoreTable()}
                            </div>
                            {studentDetail && <div className="col-4">{getDetail()}</div>}
                        </div>
                    )
                } else {
                    let dateBetween = `${utils.getLocaleDateTime(result.answer_show_start_time)} ${t(
                        "and"
                    )} ${utils.getLocaleDateTime(result.answer_show_end_time)}`
                    return (
                        <p className="px-3 text-center timed">
                            <i className="fal fa-exclamation-circle fa-lg mr-3"></i>
                            {utils.replaceWithBold(
                                t("resultsWillBeDisplayed").replace(":field_name", dateBetween),
                                dateBetween
                            )}
                        </p>
                    )
                }
            default:
                return
        }
    }

    return (
        <>
            {utils.objectHasLength(result) && (
                <div className="result">
                    <div className="row pb-3 p-sm-4 p-lg-5 d-flex flex-column no-margin">
                        <SectionHeader
                            primaryData={result.name ?? result.name[language]}
                            secondaryData={
                                result.courses && result.courses.length === 1 ? result.courses[0].name[language] : null
                            }
                            className={
                                result.score_status === "timed" ? "justify-content-center" : "justify-content-start"
                            }
                        />
                        <div className="col px-0">{getResults()}</div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ExamReport
