import { userConstants } from "../_constants"
import { utils } from "../_helpers/utils"
const userReducerDefaultState = {
    loading: false,
    loggedIn: null,
    user: {}
}

const userReducer = (state = userReducerDefaultState, action) => {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
        case userConstants.GETUSER_REQUEST:
            return {
                ...state,
                loading: true,
                loggedIn: false
            }
        case userConstants.LOGIN_SUCCESS:
        case userConstants.GETUSER_SUCCESS:
            if (action.user.activeInstitution && action.user.activeInstitution.id) {
                utils.setStore("institutionId", action.user.activeInstitution.id)
            }
            return {
                loading: false,
                loggedIn: true,
                user: action.user
            }
        case userConstants.GETUSER_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        case userConstants.CLEAR_ERRORS:
            let { error, ...newState } = state
            return {
                ...newState
            }

        case userConstants.CHANGE_INSTITUTION_REQUEST:
            return {
                loading: false,
                loggedIn: true,
                changeInstitutionLoading: true,
                user: state.user
            }
        case userConstants.CHANGE_INSTITUTION_SUCCESS:
            // check if action.institution is an object.
            if (typeof action.institution === "object") {
                utils.setStore("institutionId", action.institution.id)
                return {
                    loading: false,
                    loggedIn: true,
                    changeInstitutionLoading: false,
                    user: {
                        ...state.user,
                        activeInstitution: action.institution
                    }
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    changeInstitutionLoading: false
                }
            }
        case userConstants.CHANGE_INSTITUTION_FAILURE:
            return {
                loading: false,
                loggedIn: true,
                user: state.user,
                changeInstitutionLoading: false
            }
        default:
            return state
    }
}

export default userReducer
