import { userConstants } from "../_constants"
import { userService } from "../services/user.service"
import { institutionService } from "../services/institution.service"
import { utils } from "../_helpers/utils"

export const userActions = {
    clearErrors,
    logout,
    changeInstitution
}

function clearErrors() {
    return {
        type: userConstants.CLEAR_ERRORS
    }
}

function logout() {
    return (dispatch) => {
        userService.logout().then(
            () => {
                dispatch(logoutSuccess())
                window.location.replace(process.env.REACT_APP_SSO_API_URL + "/logout")
            },
            (error) => {
                dispatch(logoutSuccess())
                window.location.replace(process.env.REACT_APP_SSO_API_URL + "/logout")
            }
        )
    }

    function logoutSuccess() {
        return { type: userConstants.LOGOUT }
    }
}

function changeInstitution(institutionId) {
    return (dispatch) => {
        dispatch(request())
        institutionService.changeInstitution(institutionId).then(
            (institution) => {
                dispatch(success(institution))
            },
            (error) => {
                utils.deleteStore("institutionId")
                dispatch(failure(error))
            }
        )

        function request() {
            return { type: userConstants.CHANGE_INSTITUTION_REQUEST }
        }

        function success(institution) {
            return { type: userConstants.CHANGE_INSTITUTION_SUCCESS, institution }
        }

        function failure(error) {
            return { type: userConstants.CHANGE_INSTITUTION_FAILURE, error }
        }
    }
}